import React from 'react'

import {
  Container,
  Title,
  Description,
  EmphasisText,
  EmphasisNum,
} from './Layout'

/**
 */
export const FirstSection = () => (
  <Container>
    <Title>
      Search for building materials
      <EmphasisText>directly.</EmphasisText>
    </Title>
    <Description>
      Over
      <EmphasisNum>1,800,000</EmphasisNum>registered products.
    </Description>
    <Description>
      Over
      <EmphasisNum>63,000</EmphasisNum>BIM data registrations.
    </Description>
  </Container>
)

/**
 */
export const SecondSection = () => (
  <Container>
    <Title>
      It can be used <EmphasisText>for free.</EmphasisText>
    </Title>
  </Container>
)

/**
 */
export const ThirdSection = () => (
  <Container>
    <Title>
      Project-based,
      <br />
      Building material<EmphasisText>management.</EmphasisText>
    </Title>
  </Container>
)

/**
 */
export const FourthSection = () => (
  <Container>
    <Title>
      Of business<EmphasisText>Efficiency / DX</EmphasisText> teeth,
      <br />
      First on Arch-LOG.
    </Title>
  </Container>
)

/**
 */
export const FifthSection = () => (
  <Container>
    <Title>
      <EmphasisText>Share</EmphasisText> with anyone
    </Title>
  </Container>
)

/**
 */
export const SixSection = () => (
  <Container>
    <Title>
      <EmphasisText>BIM</EmphasisText> Correspondence.
    </Title>
  </Container>
)
