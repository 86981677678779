import React from 'react'

import * as styles from './styles.module.scss'
import * as Links from '@arch-log/webapp.shared/Links'
import * as Literals from './Literals'
import * as Layout from './Layout'

import {
  News as NewsImage,
  Materia as MateriaImage,
} from 'components/Images/Top'

import { useTranslation } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
const About = () => (
  <div className={styles.About}>
    <Links.Static.ToAbout className={styles.About__Link}>
      <Literals.Abount />
    </Links.Static.ToAbout>
  </div>
)

/**
 */
const PrSite = () => (
  <div className={styles.About}>
    <Links.Externals.ToPrSite
      className={styles.About__Link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Literals.PrSite />
    </Links.Externals.ToPrSite>
  </div>
)

/**
 */
const Showcase = () => (
  <div className={styles.ShowCase}>
    <Links.Showcase.ToTop className={styles.ShowCase__Link}>
      <Literals.Showcase />
    </Links.Showcase.ToTop>
  </div>
)

/**
 */
const HowTo = () => (
  <div className={styles.HowTo}>
    <Links.Static.ToHowTo className={styles.HowTo__Link}>
      <Literals.HowTo />
    </Links.Static.ToHowTo>
  </div>
)

/**
 */
const ProductRequest = () => (
  <div className={styles.HowTo}>
    <Links.ProductRequestForm.ToForm className={styles.HowTo__Link}>
      <Literals.ProductRequest />
    </Links.ProductRequestForm.ToForm>
  </div>
)

/**
 */
const News = () => {
  const Link = ({ children }) => (
    <Links.ToNews className={styles.News__Link}>{children}</Links.ToNews>
  )

  return (
    <LogoLinkComponent
      className={styles.News}
      components={{
        Image: NewsImage,
        Description: Literals.NewsDescription,
        Link,
      }}
    />
  )
}

/**
 */
const Materia = () => {
  const Link = ({ children }) => (
    <Links.ToMateria className={styles.Materia__Link}>
      {children}
    </Links.ToMateria>
  )

  return (
    <LogoLinkComponent
      className={styles.Materia}
      components={{
        Image: MateriaImage,
        Description: Literals.MateriaDescription,
        Link,
      }}
    />
  )
}

/**
 */
const LogoLinkComponent = ({
  className,
  components: { Link, Image, Description },
}) => {
  return (
    <div className={className}>
      <Link>
        <div className={styles.LogWrapper}>
          <Image />
        </div>
        <div className={styles.Description}>
          <Description />
        </div>
      </Link>
    </div>
  )
}

/**
 */
const LanguageVisibility = ({ children }) => {
  const { visible } = useLanguageVisiblity()

  if (!visible) {
    return <></>
  }

  return <>{children}</>
}

/**
 */
export const useLanguageVisiblity = () => {
  const {
    i18n: { language = 'en' },
  } = useTranslation()

  return { visible: language === 'ja' }
}

/**
 */
export const Default = ({ children }) => (
  <Layout.Container>
    <Layout.Top>
      <PrSite />
      <Showcase />
      <ProductRequest />
      <HowTo />
    </Layout.Top>
    <LanguageVisibility>
      <Layout.Bottom>
        <News />
        <Materia />
      </Layout.Bottom>
    </LanguageVisibility>
  </Layout.Container>
)
