import React from 'react'

import * as Scope from '@arch-log/webapp.modules/maintenance/components/Scope'
import * as Styled from './Styled'

/**
 */
export const Message = () => {
  const [result] = Scope.useData()
  const text = result?.top?.message?.text
  const url = result?.top?.message?.url

  if (!text) {
    return null
  }

  return <Styled.Message url={url}>{text}</Styled.Message>
}
