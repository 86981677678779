import React from 'react'

import { StaticImage } from 'gatsby-plugin-image'

export { ArchLogNews as News, ArchMateria as Materia } from 'images/top/index'

/**
 */
export const One = ({ className }) => (
  <StaticImage
    src="../../images/bg/top_bg1.jpg"
    alt="TopImage"
    quality={100}
    className={className}
  />
)

/**
 */
export const Two = ({ className }) => (
  <StaticImage
    src="../../images/bg/top_bg2.jpg"
    alt="TopImage"
    quality={100}
    className={className}
  />
)

/**
 */
export const Three = ({ className }) => (
  <StaticImage
    src="../../images/bg/top_bg3.jpg"
    alt="TopImage"
    quality={100}
    className={className}
  />
)

/**
 */
export const Four = ({ className }) => (
  <StaticImage
    src="../../images/bg/top_bg4.jpg"
    alt="TopImage"
    quality={100}
    className={className}
  />
)

/**
 */
export const Five = ({ className }) => (
  <StaticImage
    src="../../images/bg/top_bg5.jpg"
    alt="TopImage"
    quality={100}
    className={className}
  />
)

/**
 */
export const Six = ({ className }) => (
  <StaticImage
    src="../../images/bg/top_bg6.jpg"
    alt="TopImage"
    quality={100}
    className={className}
  />
)
