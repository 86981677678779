import React from 'react'

import * as MaintenanceRemote from '@arch-log/webapp.modules/maintenance/components/Remote'

/**
 */
export const Initializer = ({ children }) => {
  return (
    <MaintenanceRemote.Queries.TopPage.LazyLoader>
      {children}
    </MaintenanceRemote.Queries.TopPage.LazyLoader>
  )
}

/**
 */
export const MaintenanceLoad = () => {
  const [load] = MaintenanceRemote.Queries.TopPage.useLoad()

  React.useEffect(() => {
    load()
  }, [])

  return null
}
