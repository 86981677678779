import React from 'react'

import * as Containers from './Containers'
import * as Layout from './Layout'

/**
 */
export const Content = ({
  components: {
    Layout: {
      Wrapper = Layout.Wrapper,
      Background = Layout.Background,
      Headline = Layout.Headline,
      Notice = Layout.Notice,
      Search = Layout.Search,
      Links = Layout.Links,
    } = {},
  } = {},
}) => (
  <Wrapper>
    <Background>
      <Containers.BackgroundImages />
    </Background>
    <Notice>
      <Containers.Notify />
    </Notice>
    <Headline>
      <Containers.Headline />
      <Containers.Description />
    </Headline>
    <Search>
      <Containers.Search />
    </Search>
    <Links>
      <Containers.Links />
    </Links>
  </Wrapper>
)
