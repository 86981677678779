import React from 'react'

import { Loader } from '@arch-log/webapp.atomics/components/Loader'

import * as Maintenance from '@arch-log/webapp.modules/maintenance/components'
import * as Containers from './Containers'

/**
 */
export const Default = () => {
  return (
    <Maintenance.Remote.Queries.TopPage.Loader>
      <Containers.Message />
    </Maintenance.Remote.Queries.TopPage.Loader>
  )
}
