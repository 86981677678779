import React from 'react'

import * as styles from './layout.module.scss'

/**
 */
export const Wrapper = ({ children }) => (
  <div className={styles.Wrapper}>{children}</div>
)

/**
 */
export const Background = ({ children }) => (
  <div className={styles.Background}>{children}</div>
)

/**
 */
export const Notice = ({ children }) => (
  <div className={styles.Notice}>{children}</div>
)

/**
 */
export const Headline = ({ children }) => (
  <div className={styles.Headline}>{children}</div>
)

/**
 */
export const Search = ({ children }) => <>{children}</>

/**
 */
export const Links = ({ children }) => <>{children}</>
