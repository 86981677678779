import React from 'react'

import { query } from './query.js'
import { Context } from './Context.jsx'
import { useQuery } from '@apollo/client'
import { Provider as ScopeProvider } from '@arch-log/webapp.modules/maintenance/components/Scope/Provider.jsx'

/**
 */
export const Loader = ({
  components: { Loading = null } = {},
  children,
  ...props
}) => {
  const { data, loading, ...more } = useQuery(query)

  const result = React.useMemo(() => {
    return data?.result
  }, [data?.result])

  if (Loading && loading) {
    return <Loading />
  }

  return (
    <Context.Provider
      value={{
        ...more,
        data,
        loading,
        result,
      }}
    >
      <ScopeProvider result={result} {...props}>
        {children}
      </ScopeProvider>
    </Context.Provider>
  )
}
