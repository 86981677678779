import * as Renderers from '@arch-log/webapp.modules/maintenance/components/PropertyRenderers'

import { withContextValue } from '@arch-log/webapp.modules/utils/withContext'
import { Context } from '@arch-log/webapp.modules/maintenance/components/Scope/Context'

/**
 */
export const Text = withContextValue(
  Context,
  ({ result }) => result.top.message.text,
)(Renderers.Top.Message.Text)

/**
 */
export const Url = withContextValue(Context, ({ result }) => ({
  url: result.top.message.url,
  alt: result.top.message.text,
}))(Renderers.Top.Message.Url)
