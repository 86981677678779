import React from 'react'

import FirstSectionImg from 'images/top/first_section-text.png'
import SecondSectionImg from 'images/top/second_section-text.png'
import ThirdSectionImg from 'images/top/third_section-text.png'
import FourthSectionImg from 'images/top/fourth_section-text.png'
import FifthSectionImg from 'images/top/fifth_section-text.png'
import SixSectionImg from 'images/top/six_section-text.png'

import * as styles from './styles.module.scss'

/**
 */
export const FirstSection = () => {
  return (
    <div className={styles.Container}>
      <img
        className={styles.Image}
        src={FirstSectionImg}
        alt="Building materials,Direct search"
      />
    </div>
  )
}

/**
 */
export const SecondSection = () => {
  return (
    <div className={styles.Container}>
      <img
        className={styles.Image}
        src={SecondSectionImg}
        alt="For free,It can be used"
      />
    </div>
  )
}

/**
 */
export const ThirdSection = () => {
  return (
    <div className={styles.Container}>
      <img
        className={styles.Image}
        src={ThirdSectionImg}
        alt="Project-based,
    Building materialsmanagement"
      />
    </div>
  )
}

/**
 */
export const FourthSection = () => {
  return (
    <div className={styles.Container}>
      <img
        className={styles.Image}
        src={FourthSectionImg}
        alt="Of businessEfficiency  DXteeth、
    First on Arch-LOG"
      />
    </div>
  )
}

/**
 */
export const FifthSection = () => {
  return (
    <div className={styles.Container}>
      <img
        className={styles.Image}
        src={FifthSectionImg}
        alt="Everyone、sharecan"
      />
    </div>
  )
}

/**
 */
export const SixSection = () => {
  return (
    <div className={styles.Container}>
      <img
        className={styles.Image}
        src={SixSectionImg}
        alt="BIM Correspondence"
      />
    </div>
  )
}
