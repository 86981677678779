import { gql } from '@apollo/client'

/**
 */
export const query = gql`
  query Maintenance {
    result: maintenance {
      top {
        message {
          text
          url
        }
      }
    }
  }
`
