import React from 'react'

import * as styles from './styles.module.scss'

export const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

export const Title = ({ children }) => (
  <h3 className={styles.Title}>{children}</h3>
)

export const Description = ({ children }) => (
  <p className={styles.Description}>{children}</p>
)

export const EmphasisText = ({ children }) => (
  <span className={styles.EmphasisText}>{children}</span>
)

export const EmphasisNum = ({ children }) => (
  <span className={styles.EmphasisNum}>{children}</span>
)
