import React from 'react'

import { Trans } from 'react-i18next'
import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const PrSite = translated('app')('TopPage.FirstViewSection.Link.PrSite')

/**
 */
export const Abount = translated('app')('TopPage.FirstViewSection.Link.Abount')

/**
 */
export const Showcase = translated('app')(
  'TopPage.FirstViewSection.Link.Showcase',
)

/**
 */
export const HowTo = translated('app')('TopPage.FirstViewSection.Link.HowTo')

/**
 */
export const ProductRequest = translated('app')(
  'TopPage.FirstViewSection.Link.ProductRequest',
)

/**
 */
export const NewsDescription = () => {
  const { t } = useTranslation('app')

  return <Trans i18nKey={t('TopPage.FirstViewSection.Link.News.Description')} />
}

/**
 */
export const MateriaDescription = () => {
  const { t } = useTranslation('app')

  return (
    <Trans i18nKey={t('TopPage.FirstViewSection.Link.Materia.Description')} />
  )
}
