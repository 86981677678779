import React from 'react'

import SarchImg from 'images/top/button_sarch_icon.png'
import { withCustomComponents } from '@arch-log/webapp.atomics/components/Rules'

import * as Base from '@arch-log/webapp.shared/KeywordSearch'
import * as styles from './styles.module.scss'

const CustomKeyword = ({ ...props }) => (
  <Base.Form.Keyword
    className={styles.Keyword}
    placeholder={'Search...'}
    {...props}
  />
)

const CustomSubmit = ({ ...props }) => (
  <Base.Form.Submit className={styles.Submit} {...props}>
    <img src={SarchImg} alt="Sarch" className={styles.Submit_Icon} />
  </Base.Form.Submit>
)

const CustomContainer = ({ children, ...props }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 */
export const Default = withCustomComponents(Base.Default, {
  Form: {
    Inputs: {
      Keyword: CustomKeyword,
    },
    Submit: CustomSubmit,
  },
  Layout: {
    Container: CustomContainer,
  },
})
