// extracted by mini-css-extract-plugin
export var About = "styles-module--About--hPPFp";
export var About__Link = "styles-module--About__Link--DPanh";
export var Bottom = "styles-module--Bottom--GtIsS";
export var Container = "styles-module--Container--rH6fR";
export var Description = "styles-module--Description--ptpB8";
export var HowTo = "styles-module--HowTo--1cIiR";
export var HowTo__Link = "styles-module--HowTo__Link--zBqfw";
export var LogWrapper = "styles-module--LogWrapper--59CmT";
export var Materia = "styles-module--Materia--az9xf";
export var Materia__Link = "styles-module--Materia__Link--dXsE+";
export var News = "styles-module--News--AIq4u";
export var News__Link = "styles-module--News__Link--3uk9u";
export var ShowCase = "styles-module--ShowCase--JVjb9";
export var ShowCase__Link = "styles-module--ShowCase__Link--3yI72";
export var Top = "styles-module--Top--NaCt0";