import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Headline = ({ children }) => {
  return <h2 className={styles.Headline}>{children}</h2>
}

/**
 */
export const Description = ({ children }) => (
  <p className={styles.Description}>{children}</p>
)

/**
 */
export const DescriptionNum = ({ children }) => (
  <span className={styles.Description_Num}>{children}</span>
)
