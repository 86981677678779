import React from 'react'

import CategoryImg from 'images/top/button_white_icon.png'
import ManufactureImg from 'images/top/button_beige_icon.png'

import * as Literals from './Literals'
import * as Search from './Search'
import * as Layout from './Layout'
import * as styles from './styles.module.scss'

//const Search = () => (
//  <div className={styles.Search}>
//    <input
//      type="text"
//      placeholder={'Search...'}
//      className={styles.InputTextArea}
//    />
//    <div className={styles.Search_Button}>
//      <img src={SarchImg} alt="Sarch" className={styles.Search_Button_Icon} />
//    </div>
//  </div>
//)

const Category = () => (
  <>
    <div className={styles.Button}>
      <img src={CategoryImg} alt="Category" className={styles.Button_Icon} />
    </div>
  </>
)

const Manufacture = () => (
  <>
    <div className={styles.Button}>
      <img
        src={ManufactureImg}
        alt="Manufacture"
        className={styles.Button_Icon}
      />
    </div>
  </>
)

export const Default = ({
  components: { Layout: { Container = Layout.Container } = {} } = {},
}) => (
  <Container>
    <Search.Default />
    {/*
    <Category />
    <Manufacture />
    */}
  </Container>
)
