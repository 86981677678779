import React from 'react'

import * as BaseNotify from 'components/TopPageNotify'
import * as BaseLinks from './Links'
import * as BaseSearch from './Searchs'
import * as Images from 'components/Images/Top'
import * as Styled from './Styled'
import * as Literals from './Literals'
import * as styles from './styles.module.scss'

/**
 */
export const Notify = () => <BaseNotify.Default />

/**
 */
export const BackgroundImages = () => (
  <>
    <Images.One className={styles.Background_Image} />
    <Images.Two className={styles.Background_Image} />
    <Images.Three className={styles.Background_Image} />
    <Images.Four className={styles.Background_Image} />
    <Images.Five className={styles.Background_Image} />
    <Images.Six className={styles.Background_Image} />
  </>
)

/**
 */
export const Headline = ({
  components: { Decorator = Styled.Headline } = {},
}) => (
  <Decorator>
    <Literals.Headline />
  </Decorator>
)

/**
 */
export const Description = ({
  components: {
    Decorator = Styled.Description,
    Num = Styled.DescriptionNum,
  } = {},
}) => (
  <Decorator>
    <Literals.Description components={{ Num: <Num /> }} />
  </Decorator>
)

export const Links = BaseLinks.Default

export const Search = BaseSearch.Default
