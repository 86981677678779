import React from 'react'

import * as ValueRenderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const Text = ({
  render: Renderer = ValueRenderers.TextRenderer,
  value,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}

/**
 */
export const Url = ({
  render: Renderer = ValueRenderers.LinkRenderer,
  value,
  defaultValue = null,
  ...props
}) => {
  const { url = null, alt = null } = value ?? defaultValue

  return (
    <Renderer
      target="_blank"
      rel="noopener noreferrer"
      value={url}
      alt={alt}
      {...props}
    />
  )
}
