import React from 'react'

import {
  Container,
  Title,
  Description,
  EmphasisText,
  EmphasisNum,
} from './Layout'

/**
 */
export const FirstSection = () => (
  <Container>
    <Title>
      建材を、<EmphasisText>直接検索</EmphasisText>。
    </Title>
    <Description>
      登録商品数<EmphasisNum>1,800,000</EmphasisNum>点以上
    </Description>
    <Description>
      BIMデータ登録数<EmphasisNum>63,000</EmphasisNum>点以上
    </Description>
  </Container>
)

/**
 */
export const SecondSection = () => (
  <Container>
    <Title>
      <EmphasisText>無料</EmphasisText>で、使える。
    </Title>
  </Container>
)

/**
 */
export const ThirdSection = () => (
  <Container>
    <Title>
      プロジェクトベースの、
      <br />
      建材<EmphasisText>管理</EmphasisText>。
    </Title>
  </Container>
)

/**
 */
export const FourthSection = () => (
  <Container>
    <Title>
      業務の
      <EmphasisText>効率化・DX</EmphasisText>は、
      <br />
      まずArch-LOGで。
    </Title>
  </Container>
)

/**
 */
export const FifthSection = () => (
  <Container>
    <Title>
      皆で、<EmphasisText>共有</EmphasisText>できる。
    </Title>
  </Container>
)

/**
 */
export const SixSection = () => (
  <Container>
    <Title>
      <EmphasisText>BIM</EmphasisText>対応。
    </Title>
  </Container>
)
