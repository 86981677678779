import React from 'react'

import FirstSectionImg from 'images/top/first_section.jpg'
import SecondSectionImg from 'images/top/second_section.jpg'
import ThirdSectionImg from 'images/top/third_section.jpg'
import FourthSectionImg from 'images/top/fourth_section.jpg'
import FifthSectionImg from 'images/top/fifth_section.jpg'
import SixSectionImg from 'images/top/six_section.jpg'

import * as styles from './styles.module.scss'

/**
 */
export const FirstSection = () => {
  return (
    <img
      className={styles.Image}
      src={FirstSectionImg}
      alt="Building materials,Direct search"
    />
  )
}

/**
 */
export const SecondSection = () => {
  return (
    <img
      className={styles.Image}
      src={SecondSectionImg}
      alt="For free,It can be used"
    />
  )
}

/**
 */
export const ThirdSection = () => {
  return (
    <img
      className={styles.Image}
      src={ThirdSectionImg}
      alt="Project-based,
    Building materialsmanagement"
    />
  )
}

/**
 */
export const FourthSection = () => {
  return (
    <img
      className={styles.Image}
      src={FourthSectionImg}
      alt="Of businessEfficiency  DXteeth、
    First on Arch-LOG"
    />
  )
}

/**
 */
export const FifthSection = () => {
  return (
    <img
      className={styles.Image}
      src={FifthSectionImg}
      alt="Everyone、sharecan"
    />
  )
}

/**
 */
export const SixSection = () => {
  return (
    <img
      className={styles.Image}
      src={SixSectionImg}
      alt="BIM Correspondence"
    />
  )
}
