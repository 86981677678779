import React from 'react'

import { Context } from './Context'

/**
 */
export const useContext = () => {
  return React.useContext(Context)
}

/**
 */
export const useLoad = () => {
  const { load: baseLoad, loading, result } = useContext()

  const load = React.useCallback(
    (options) => {
      baseLoad({ fetchPolicy: 'network-only', ...options })
    },
    [baseLoad],
  )

  const isLoaded = (result ?? false) && !loading

  return [load, isLoaded]
}
