import React from 'react'

import ArchMateriaSrc from './ArchMateria_logo.svg'
import ArchLogNewsSrc from './ArchLOGNews_logo.svg'

/**
 */
export const ArchMateria = (props) => (
  <img src={ArchMateriaSrc} alt="Arch-Materia" {...props} />
)

/**
 */
export const ArchLogNews = (props) => (
  <img src={ArchLogNewsSrc} alt="Arch-LOG_News" {...props} />
)
