import React from 'react'

import { Content as FirstViewSectionContent } from './FirstViewSection'
import { Content as MeritSectionContent } from './MeritSection'

import * as Containers from './Containers'
import * as styles from './styles.module.scss'

/**
 */
const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 */
const Body = () => (
  <div className={styles.Body}>
    <FirstViewSectionContent />
    <MeritSectionContent />
  </div>
)

/**
 */
export const Content = ({ children }) => {
  return (
    <Containers.Initializer>
      <Containers.MaintenanceLoad />
      <Container>
        <Body />
      </Container>
    </Containers.Initializer>
  )
}
