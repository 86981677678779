import React from 'react'

import { Context } from './Context.jsx'
import { query } from './query.js'
import { useLazyQuery } from '@apollo/client'
import { Provider as ScopeProvider } from '@arch-log/webapp.modules/maintenance/components/Scope/Provider.jsx'

/**
 */
export const LazyLoader = ({ children, ...props }) => {
  const [load, { loading, data, ...rem }] = useLazyQuery(query)

  const result = React.useMemo(() => {
    return data?.result
  }, [data?.result])

  return (
    <Context.Provider value={{ load, loading, result, ...rem }}>
      <ScopeProvider result={result} {...props}>
        {children}
      </ScopeProvider>
    </Context.Provider>
  )
}
