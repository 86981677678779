import React from 'react'

import * as styles from './styles.module.scss'
import * as Layout from './Layout'
import * as Images from './Images'
import * as TextImages from './TextImages'
import * as Texts from './Texts'

import { Default as Links } from './Links'

/**
 */
export const Container = ({ children }) => {
  return <div className={styles.Container}>{children}</div>
}

/**
 */
export const First = () => {
  return (
    <Layout.SectionContainer>
      <Images.FirstSection />
      <TextImages.FirstSection />
      <Texts.FirstSection />
    </Layout.SectionContainer>
  )
}

/**
 */
export const Second = () => {
  return (
    <Layout.SectionContainer>
      <Images.SecondSection />
      <TextImages.SecondSection />
      <Texts.SecondSection />
    </Layout.SectionContainer>
  )
}

/**
 */
export const Third = () => {
  return (
    <Layout.SectionContainer>
      <Images.ThirdSection />
      <TextImages.ThirdSection />
      <Texts.ThirdSection />
    </Layout.SectionContainer>
  )
}

/**
 */
export const Fourth = () => {
  return (
    <Layout.SectionContainer>
      <Images.FourthSection />
      <TextImages.FourthSection />
      <Texts.FourthSection />
    </Layout.SectionContainer>
  )
}

/**
 */
export const Fifth = () => {
  return (
    <Layout.SectionContainer>
      <Images.FifthSection />
      <TextImages.FifthSection />
      <Texts.FifthSection />
    </Layout.SectionContainer>
  )
}

/**
 */
export const Six = () => {
  return (
    <Layout.SectionContainer>
      <Images.SixSection />
      <TextImages.SixSection />
      <Texts.SixSection />
    </Layout.SectionContainer>
  )
}

/**
 */
const Body = () => (
  <div className={styles.Body}>
    <First />
    <Second />
    <Third />
    <Fourth />
    <Fifth />
    <Six />
  </div>
)

/**
 */
const Footer = () => (
  <div className={styles.Footer}>
    <Links />
  </div>
)

/**
 */
export const Content = () => {
  return (
    <Container>
      <Body />
      <Footer />
    </Container>
  )
}
