import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Message = ({ children, url }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    className={styles.Message}
  >
    <span className={styles.MessageText}>{children}</span>
  </a>
)
