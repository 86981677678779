import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 */
export const Top = ({ children }) => {
  return <div className={styles.Top}>{children}</div>
}

/**
 */
export const Bottom = ({ children }) => {
  return <div className={styles.Bottom}>{children}</div>
}
