import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Merit = translated('app')('TopPage.MeritSection.Link.Merit')

/**
 */
export const Registration = translated('app')(
  'TopPage.MeritSection.Link.Registration',
)
