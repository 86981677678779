import React from 'react'

import * as styles from './styles.module.scss'
import * as Links from '@arch-log/webapp.shared/Links'
import * as Auth from '@arch-log/webapp.modules/auth.v2/components'
import * as Literals from './Literals'

/**
 */
const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 */
const Merit = () => (
  <div className={styles.Merit}>
    <Links.Static.ToMerit className={styles.Merit__Link}>
      <Literals.Merit />
    </Links.Static.ToMerit>
  </div>
)

/**
 */
const Registration = () => {
  const { isAuthenticated } = Auth.useAuth()

  if (isAuthenticated) {
    return null
  }

  return (
    <div className={styles.Registration}>
      <Links.Registration.ToTop className={styles.Registration__Link}>
        <Literals.Registration />
      </Links.Registration.ToTop>
    </div>
  )
}

/**
 */
export const Default = ({ children }) => (
  <Container>
    <Merit />
    <Registration />
  </Container>
)
