import React from 'react'

import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'

import { Trans } from 'react-i18next'

/**
 */
export const Headline = translated('app')('TopPage.FirstViewSection.Headline')

const useLiterals = () => {
  const { t } = useTranslation('app')

  const descriptionI18nkey = t('TopPage.FirstViewSection.Description')

  return {
    t,
    descriptionI18nkey,
  }
}

/**
 */
export const Description = ({ components }) => {
  const { t, descriptionI18nkey } = useLiterals()

  return <Trans t={t} i18nKey={descriptionI18nkey} components={components} />
}
